var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-second-add-other",
      "title": "Chọn gói dịch vụ thêm",
      "title-class": "text-airline font-medium-3 fw-700",
      "body-class": "px-75",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "d-flex-center w-100"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-2 d-flex-center",
          attrs: {
            "variant": "info",
            "pill": ""
          },
          on: {
            "click": _vm.handleConfirmChange
          }
        }, [_c('div', {
          staticClass: "d-flex-center ml-25"
        }, [_vm.loadingNextButton ? _c('BSpinner', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }) : _c('span', [_vm._v("Tiếp tục")])], 1)])], 1)];
      }
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isShow,
      "rounded": "sm",
      "no-fade": "",
      "variant": "white",
      "spinner-variant": "info",
      "opacity": 0.9,
      "no-center": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center py-2 mt-4"
        }, [_c('p', {
          staticClass: "font-weight-bolder text-airline",
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v(" Đang tải dữ liệu ... ")]), _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "info"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "fw-700 text-medium-1"
  }, [_vm._v(" Chọn gói dịch vụ mua thêm: ")]), _vm._l(_vm.arrDataHandle, function (segmentData, indexSegmentData) {
    return _c('b-card', {
      key: indexSegmentData,
      staticClass: "border-warning shadow-lg",
      attrs: {
        "header-bg-variant": "light-warning",
        "header-class": "py-50 px-1",
        "body-class": "pb-0 pt-50 px-75"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-airline fw-700"
          }, [_c('span', {
            staticClass: "font-small-4 mr-50"
          }, [_vm._v("Hành trình: ")]), _vm._v(" " + _vm._s(segmentData.segment.departure.iataCode) + " - " + _vm._s(segmentData.segment.arrival.iataCode) + " (" + _vm._s(_vm.convertISODateTime(segmentData.segment.departure.at, segmentData.segment.departure.timeZone).date) + ") ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('CardNamePax', {
      attrs: {
        "arr-pax": segmentData.arrPax,
        "name-type": 'dịch vụ'
      }
    }), _c('b-card', {
      staticClass: "mb-50",
      attrs: {
        "header-class": "px-0 pt-50 pb-50 pb-md-0",
        "body-class": "px-0 pt-50 px-md-1 px-lg-2"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex gap-1"
          }, [_c('span', {
            staticClass: "text-body fw-700"
          }, [_vm._v("Chọn gói dịch vụ: ")]), ['TH'].includes(_vm.bookingSource) ? _c('span', [_vm._v("Chỉ được chọn 1 gói dịch vụ")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [!_vm.isEmpty(_vm.getOtherServiceBySegment(segmentData)) ? _vm._l(_vm.getOtherServiceBySegment(segmentData), function (otherItem, indexOther) {
      return _c('b-row', {
        key: indexOther,
        staticClass: "mb-1 mb-md-75 justify-content-center",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('b-col', {
        staticClass: "d-flex align-items-center",
        attrs: {
          "cols": "10",
          "md": "8"
        }
      }, [_c('b-form-checkbox', {
        attrs: {
          "id": "baggageItem_".concat(indexSegmentData, "_").concat(indexOther),
          "inline": "",
          "disabled": _vm.checkDisableItem(segmentData, otherItem),
          "checked": _vm.isChecked(segmentData.newAddItems, otherItem)
        },
        on: {
          "change": function change($event) {
            return _vm.chooseItem(segmentData.newAddItems, otherItem);
          }
        }
      }, [_c('span', {
        class: "font-weight-bolder ".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-small-3')
      }, [_vm._v(" " + _vm._s(['TR'].includes(_vm.bookingSource) ? otherItem.key : _vm.resolveSsrNameByCode(otherItem.code)) + " ")]), _c('span', {
        class: "text-warning font-italic fw-700 font-medium-1 ".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-small-3')
      }, [_vm._v(" " + _vm._s(!_vm.isEmpty(otherItem) && otherItem.fares.find(function (f) {
        return ['ALL', 'ADULT', ''].includes(f.paxType);
      }) ? _vm.formatCurrency(otherItem.fares.find(function (f) {
        return ['ALL', 'ADULT', ''].includes(f.paxType);
      }).total) : '-') + " ")]), _vm.bookingSource === 'F1' && otherItem !== null && otherItem !== void 0 && otherItem.ssrValue ? _c('em', [_vm._v(" (" + _vm._s(otherItem === null || otherItem === void 0 ? void 0 : otherItem.ssrValue) + ") ")]) : _vm._e()])], 1), _c('b-col', {
        attrs: {
          "cols": "2",
          "md": "4"
        }
      }, [_vm.isChecked(segmentData.newAddItems, otherItem) ? _c('div', {
        staticClass: "d-flex align-items-center justify-content-end"
      }, [_c('div', {
        staticClass: "mr-50 d-none d-lg-inline"
      }, [_vm._v(" Số lượng: ")]), _c('div', {
        staticClass: "d-flex"
      }, [_c('b-form-input', {
        directives: [{
          name: "remove-non-numeric-chars",
          rawName: "v-remove-non-numeric-chars"
        }],
        staticClass: "mx-50 px-50 fw-700 text-center font-medium-3 rounded",
        staticStyle: {
          "width": "40px"
        },
        attrs: {
          "value": "1",
          "maxlength": 2,
          "disabled": true,
          "size": "sm"
        }
      })], 1)]) : _c('div', {
        staticClass: "d-flex align-items-center justify-content-end"
      }, [_c('div', {
        staticClass: "mr-50 text-secondary d-none d-lg-inline"
      }, [_vm._v(" Số lượng: ")]), _c('div', {
        staticClass: "d-flex"
      }, [_c('b-form-input', {
        staticClass: "mx-50 px-50 text-secondary text-center rounded",
        staticStyle: {
          "width": "40px"
        },
        attrs: {
          "value": '',
          "size": "sm",
          "disabled": true
        }
      })], 1)])])], 1);
    }) : _vm.isShow ? [_c('b-spinner', {
      staticClass: "m-1",
      attrs: {
        "variant": "info"
      }
    })] : [_c('span', {
      staticClass: "text-warning fw-700"
    }, [_vm._v(" Không có gói dịch vụ trên chặng này ")])]], 2)], 1);
  }), _c('ModalConfirmAddBaggage', {
    attrs: {
      "arr-data-to-confirm": _vm.arrDataToConfirm
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }